
import exam_img from '../../../assets/images/ofa/verifica.svg'
import wrong_img from '../../../assets/images/ofa/question.svg'
import arrow_img from '../../../assets/images/ofa/circle_arrow.svg'

import './style.css'
import { useSelector } from "react-redux";
import { selectUserData } from "../../../features/User/userSlice";
import { selectAppInfo } from "../../../services/utils/appInfoSlice";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchGetUserExams } from "../ExamsApi";
import { OFA_TENANT, TENANT } from '../../../config/constants';
import { arraysEqual } from '../../../utils/utils';

export default function OfaExamBanner () {

    const userInfo = useSelector(selectUserData)
    const appInfo = useSelector(selectAppInfo)
    const navigate = useNavigate()

    //OFA-ONLY
    const [showExamBanner, setShowExamBanner] = useState(false)
    const [showContactBanner, setShowContactBanner] = useState(false)
    const [userExams, setUserExams] = useState(null)

    const loadUserExams = async () => {
        const response = await fetchGetUserExams({has_started: true})
        if(response.success){
            setUserExams(response?.data?.user_exams)
        }   
    }

    const checkOfaUserGroups = async () => {
        if(OFA_TENANT.includes(TENANT)){
            let show_exam_banner = false
            let show_contact_banner = false
            const user_groups = userInfo?.groups || []

            appInfo?.personalizations?.ofa_settings?.group_settings?.forEach((ofa_group)=>{
                if (user_groups?.includes(ofa_group?.group_id)){
                    //if user has ofa group check if it has completed group
                    if(user_groups?.includes(ofa_group?.video_completion_group_id)){
                        //if the user has the first test group check if it has also the retry group and if yes load exams if not altready did
                        if(user_groups?.includes(ofa_group?.retry_exam_group_id) ){
                            if (userExams && !userExams?.find(e => e.allowed_groups?.includes(ofa_group?.retry_exam_group_id) && e.end_date))
                                show_exam_banner = true
                            else if( userExams && userExams?.find(e => e.end_date 
                                && e.allowed_groups?.includes(ofa_group?.retry_exam_group_id) 
                                && !e?.thresholds?.find(t => t.threshold_type==="certificate_generation")?.has_been_passed))
                                show_contact_banner = true
                        } else {
                            //else we show the banner
                            show_exam_banner = true
                        }
                    } // if it hasn't completed the group we do nothing
                }
            })
            
            if(show_exam_banner)
                setShowExamBanner(true)
            else if(show_contact_banner)
                setShowContactBanner(true)
        }
    }

    const navigateToExam = () => {
        navigate('/exams', {replace:true})
    }

    const navigateToContact = () => {
        navigate('/account', {replace:true, state:{activeModule:'contact'}})
    }

    useEffect(()=>{
        checkOfaUserGroups()
    },[userInfo?.groups, userExams])

    useEffect(()=>{
        loadUserExams()
    },[])

    return (
        <>
            {
                showExamBanner &&
                    <>
                        <div className="ofa_banner_top pointer" onClick={navigateToExam}>
                            <img src={exam_img} alt="" />
                            <div className="ms-1">
                                <h3 className="mb-0">Verifica ufficiale OFA sbloccata</h3>
                                <p className="mb-0">Svolgi la verifica ufficiale e ottieni il certificato</p>
                            </div>
                            <img src={arrow_img} alt="" className="ms-2 pointer" />
                        </div>
                        <div className="mb-5 pt-3"></div>
                    </> 
            }
            {
                (showContactBanner) &&
                    <>
                        <div className="ofa_banner_top pointer" onClick={navigateToContact}>
                            <img src={wrong_img} style={{maxWidth:'32px'}} alt="" />
                            <div className="ms-1">
                                <h3 className="mb-0">Verifica ufficiale non superata?</h3>
                                <p className="mb-0">Contattaci per sbloccare un ulteriore tentativo</p>
                            </div>
                            <img src={arrow_img} alt="" className="ms-2 pointer" />
                        </div>
                        <div className="mb-5 pt-3"></div>
                    </>
            }
        </>
    )
}