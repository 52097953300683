import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { fetchGetExams, fetchGetPendingExams, fetchGetUserExams } from "./ExamsApi"
import { toast } from 'react-toastify';
import { selectTranslations } from "../../services/i18n/i18nSlice";
import { Text } from "../../components/Text";
import { TitleH3 } from "../../components/TitleH3";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import img_user from './assets/images/user.svg'
import img_users from './assets/images/users.svg'
import img_arrow from './assets/images/right.svg'
import img_clock from './assets/images/clock_green.svg'
import timer_img from './assets/images/timer.svg'
import score_img from './assets/images/score.svg'
import details_img from './assets/images/details.svg'
import rank_img from './assets/images/rank.svg'
import exam_img from '../../components/Navigation/icons/verifica.svg'
import hands_img from '../../assets/images/high-five-hand.svg'
import history_img from '../../assets/images/history.svg'
import question_img from '../../assets/images/ofa/question.svg'

import { Link, useNavigate } from "react-router-dom";
import { selectAppInfo } from "../../services/utils/appInfoSlice";
import { selectUserData } from "../../features/User/userSlice";
import { Button } from "../../components/Button";

export default function ExamsList (props) {

    const t = useSelector(selectTranslations)
    const navigate = useNavigate()
    const [examsData, setExamsData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [fetchState, setFetchState] = useState(
       { start_cursor: null, limit: 8}
    )

    //OFA-ONLY
    const [showExamBanner, setShowExamBanner] = useState(false)
    const [showContactBanner, setShowContactBanner] = useState(false)
    const [hasPassedExams, setHasPassedExams] = useState(false)
    const [historyExams, setHistoryExams] = useState(null)

    const appInfo = useSelector(selectAppInfo)
    const userInfo = useSelector(selectUserData)

    const loadPendingUserExams = async () => {
        const _params = {
            ...(fetchState.limit && {limit: fetchState.limit} ),
            ...(fetchState?.start_cursor && {start_cursor: fetchState.start_cursor})
        }
        let response = await fetchGetPendingExams(_params)
        if(response.success){
            let _temp_exams = response.data?.user_exams || []
            _temp_exams = _temp_exams.map((e) => {return {...e, is_started: true}})
            response = await fetchGetExams(_params)
            if(!response.success){
                toast.error(t.exams.errors.loading)
                return;
            } 
            setExamsData([..._temp_exams, ...response?.data?.exams])
            setFetchState({...fetchState, start_cursor: response?.data?.next_cursor})
            setIsLoading(false)
        } else {
            console.error(response.error)
        } 
    }

    const loadExams = async (reset = false) =>{
        const _params = {
            ...(fetchState.limit && {limit: fetchState.limit} ),
            ...(fetchState?.start_cursor && {start_cursor: fetchState.start_cursor}),
            has_started: props.showStarted
        }
        const response = await fetchGetExams(_params)
        if(response.success){
            setExamsData(reset ? response?.data?.exams : [...examsData, ...response?.data?.exams])
            setFetchState({...fetchState, start_cursor: response?.data?.next_cursor})
            setIsLoading(false)
        } else {
            toast.error(t.exams.errors.loading)
        }   
    }

    const loadHistoryExams = async (reset = false) =>{
        const _params = {
            ...(fetchState.limit && {limit: fetchState.limit} ),
            ...(fetchState?.start_cursor && {start_cursor: fetchState.start_cursor}),
            has_started: props.showStarted
        }
        const response = await fetchGetUserExams(_params)
        if(response.success){
            setExamsData(reset ? response?.data?.user_exams : [...examsData, ...response?.data?.user_exams])
            setFetchState({...fetchState, start_cursor: response?.data?.next_cursor})
            setIsLoading(false)
        } else {
            toast.error(t.exams.errors.loading)
        }   
    }

    const checkHistoryExams = async () =>{
        const _params = {
            has_started: true
        }
        const response = await fetchGetUserExams(_params)
        if(response.success){
            setHistoryExams(response.data?.user_exams)
        } else {
            console.error(response.error)
        }   
    }

    const checkOfaUserGroups = async () => {
        if(appInfo.is_ofa){
            let show_exam_banner = false
            let show_contact_banner = false
            const user_groups = userInfo?.groups || []
            let count_passed = 0
            appInfo?.personalizations?.ofa_settings?.group_settings?.forEach((ofa_group)=>{
                if (user_groups?.includes(ofa_group?.group_id)){
                    //if user has ofa group check if it has completed group
                    if(user_groups?.includes(ofa_group?.video_completion_group_id)){
                        //if the user has the first test group check if it has also the retry group and if yes load exams if not altready did
                        if(user_groups?.includes(ofa_group?.retry_exam_group_id) ){
                            if (historyExams && !historyExams?.find(e => e.allowed_groups?.includes(ofa_group?.retry_exam_group_id) && e.end_date))
                                show_exam_banner = true
                            else if( historyExams && historyExams?.find(e => e.end_date 
                                && e.allowed_groups?.includes(ofa_group?.retry_exam_group_id) 
                                && !e?.thresholds?.find(t => t.threshold_type==="certificate_generation")?.has_been_passed))
                                show_contact_banner = true
                            else if(historyExams && historyExams?.find(e => e.end_date 
                                && e.allowed_groups?.includes(ofa_group?.retry_exam_group_id) 
                                && e?.thresholds?.find(t => t.threshold_type==="certificate_generation")?.has_been_passed))
                                count_passed += 1
                        } else {
                            //else we show the banner
                            show_exam_banner = true
                            if(historyExams && historyExams?.find(e => e.end_date 
                                && e.allowed_groups?.includes(ofa_group?.video_completion_group_id) 
                                && e?.thresholds?.find(t => t.threshold_type==="certificate_generation")?.has_been_passed))
                                count_passed += 1
                        }
                    } // if it hasn't completed the group we show waiting message
                    else {
                        show_exam_banner = true
                    }
                }
            })
            if(count_passed === appInfo?.personalizations?.ofa_settings?.group_settings?.length)
                setHasPassedExams(true)
            if(show_exam_banner)
                setShowExamBanner(true)
            else if(show_contact_banner)
                setShowContactBanner(true)
        }
    }

    const openContact = () => {
        navigate('/account', {replace:true, state:{activeModule:'contact'}})
    }

    const openHome = () => {
        navigate('/', {replace:true})
    }


    useEffect(()=>{
        if(props.showPending)
            loadPendingUserExams()
        else
            if(props.showHistory)
                loadHistoryExams(true)
            else
                loadExams(true)
        if(appInfo.is_ofa && !props.showHistory){
            checkHistoryExams()
            checkOfaUserGroups()
        }
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        checkOfaUserGroups()
        // eslint-disable-next-line
    },[historyExams])

    return(
        <>  
            {
                props.title && examsData?.length !== 0 ? 
                    <TitleH3 className="mb-3">{props.title}</TitleH3>
                : null
            }
            {examsData?.length === 0 && !isLoading && props.showStarted ?
                <div className="mt-5 pt-5 px-2">
                    { props.showHistory ? 
                        <div className="d-flex flex-column justify-content-center align-items-center mx-auto mt-5" style={{maxWidth:"400px"}}>
                            <img src={history_img} className="mb-2" style={{width:'48px'}} alt=""/>
                            <TitleH3 className="text-center">{t.exams.no_exam_completed}</TitleH3>
                            <Text className="text-center mt-2">{t.exams.no_exam_completed_text}</Text>
                        </div>
                    :
                    <>
                        {!appInfo.is_ofa ? 
                            <div className="d-flex flex-column justify-content-center align-items-center mx-auto mt-5" style={{maxWidth:"400px"}}>
                                <img src={exam_img} className="mb-2" style={{width:'48px'}} alt=""/>
                                <TitleH3 className="text-center">{t.exams.no_exam_available}</TitleH3>
                                <Text className="text-center mt-2">{t.exams.no_exam_available_text}</Text>
                            </div> :
                        <>
                            {
                                showExamBanner ?
                                    <div className="d-flex flex-column justify-content-center align-items-center mx-auto mt-5" style={{maxWidth:"400px"}}>
                                        <img src={exam_img} className="mb-2" style={{width:'48px'}} alt=""/>
                                        <TitleH3 className="text-center">{t.ofa.exam.not_available}</TitleH3>
                                        <Text className="text-center mt-2">{t.ofa.exam.not_available_text}</Text>
                                        <Button onClick={openHome}>{t.buttons.keep_training}</Button>
                                    </div>
                                : <>
                                    {hasPassedExams ?
                                        <div className="d-flex flex-column justify-content-center align-items-center mx-auto mt-5" style={{maxWidth:"400px"}}>
                                            <img src={hands_img} className="mb-2" style={{width:'48px'}} alt=""/>
                                            <TitleH3 className="text-center">{t.ofa.exam.passed}</TitleH3>
                                            <Text className="text-center mt-2">{t.ofa.exam.passed_text}</Text>
                                        </div> :
                                        <>
                                            { showContactBanner &&
                                                <div className="d-flex flex-column justify-content-center align-items-center mx-auto mt-5" style={{maxWidth:"400px"}}>
                                                    <img src={question_img} className="mb-2" style={{width:'48px'}} alt=""/>
                                                    <TitleH3 className="text-center">{t.ofa.exam.not_passed}</TitleH3>
                                                    <Text className="text-center mt-2">{t.ofa.exam.not_passed_text}</Text>
                                                    <Button onClick={openContact}>{t.ofa.exam.not_passed_cta}</Button>
                                                </div>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </>}
                    </>
                    }
                </div>
            : null}
            <div className="exams_list">
            {examsData?.map((e,i)=>{
                if(props.showHistory)
                    return <HistoryExamCard t={t} e={e} key={i}/>
                else
                    return <ExamCard t={t} e={e} key={i} showStarted={props.showStarted} />
            })}
            {isLoading && Array.from(Array(3), (v,i)=> <ExamCard key={i}/>)}
        </div>
        </>
    )
}

function ExamCard ({t, e = null,i = null, showStarted=true}) {

    const navigate = useNavigate()

    const openExam = () => {
        navigate(`/exams/preview/${e?.exam_id}`, {replace:true, state:{exam: e}})
    }

    return(
        <div className="exam_card pointer" onClick={openExam} >
            {
                e?.is_started ?
                <div className="exam_card_label">
                    <img className="align-middle me-1" src={img_clock} />
                    <span>{t.exam.in_progress}</span>
                </div> : null
            }
            <div className="exam_card_content">
                <div className="d-flex justify-content-between align-items-center">
                    <TitleH3>{e?.title || e?.exam_title}</TitleH3>
                    {e?.exam_id && showStarted ? 
                    <Link to={`/exams/preview/${e?.exam_id}`} state={{exam: e}} >
                        <img src={img_arrow} alt="" />
                    </Link> : null}
                </div>
                <Text>{e?.description || <Skeleton count={3}/>}</Text>
                {e?.exam_id ?
                    <div className="d-flex justify-content-between align-items-center">
                        {
                            showStarted && (e?.end_date || e?.exam_end_date) ?
                                <span style={{fontSize:"12px",fontWeight:"500",color:"#6D6F73"}}>{t.exams.up_to} {new Date(e?.end_date || e?.exam_end_date).toLocaleDateString()}</span>
                            : <span>
                                {e?.start_date ?
                                    <span style={{fontSize:"12px",fontWeight:"500",color:"#6D6F73"}}>{t.exams.from} {new Date(e?.start_date).toLocaleDateString()}</span>
                                : null}
                            </span>
                        }
                        {
                            e?.allowed_all ?
                                <img src={img_users} alt="" /> :
                            <img src={img_user} alt="" />
                        }
                    </div>
                : ""}
                </div>
        </div>
    )
}

function HistoryExamCard ({t, e = null,i = null}) {

    const renderTotalTime = () => {
        let total = 0
        e?.user_modules?.forEach(m=> {
            total += m.consumed_time
        });
        return total
    }
    
    return(
        <div className="exam_card pointer" >
            <div className="exam_card_content">
                <div className="d-flex justify-content-between">
                    <div className="exam_score">
                        <img src={timer_img} alt="" style={{maxWidth:'20px', verticalAlign:'sub'}}/>
                        <span>
                            {renderTotalTime()} min
                        </span>
                    </div>
                    <div className="exam_score">
                        <span>
                            {new Date(e?.end_date).toLocaleDateString()}
                        </span>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                     <TitleH3 className="mt-2">{e?.title || e?.exam_title}</TitleH3>
                </div>
                <div className="exam_score d-flex justify-content-center aling-items-center mt-2">
                    <img src={score_img} alt="" className="me-2"/>
                    <span>
                        <strong>{e?.total_score}</strong> {t.exam.points}
                    </span>
                </div>
                <div className="d-flex justify-content-around aling-items-center">
                    { e?.visible_questions_review ?
                    <div className="d-flex aling-items-center mt-2">
                        <img src={details_img} alt="" className="me-1"/>
                        <Link to={`/exams/review/${e.exam_id}`}>
                            <u>{t.exam.details}</u>
                        </Link>
                    </div> : null }
                    { e?.visible_scoreboard ?
                    <div className="d-flex aling-items-center mt-2">
                        <img src={rank_img} alt="" className="me-1"/>
                        <Link to={`/exams/scoreboard/${e.exam_id}`}>
                            <u>{t.exam.rank}</u>
                        </Link>
                    </div> : null}
                </div>
            </div>
        </div>
    )
}