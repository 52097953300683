import BottomSheet from '../../../components/_modals/BottomSheet'
import degree_img from './degree.svg'
import warning_img from './warning.svg'
import question_img from '../../../assets/images/ofa/question.svg'

import { Text } from '../../../components/Text'
import { Button } from '../../../components/Button'
import { TitleH2 } from '../../../components/TitleH2'
import { useSelector } from 'react-redux'
import { selectTranslations } from '../../../services/i18n/i18nSlice'
import { selectAppInfo } from '../../../services/utils/appInfoSlice'
import { selectUserData } from '../../../features/User/userSlice'
import { isWebview, sendTfEvent } from '../../../utils/utils'
import { useNavigate } from 'react-router-dom'

export default function ThresholdSheet({show, closeBottomSheet, thresholds, examData}) {

    const t = useSelector(selectTranslations)
    const appInfo = useSelector(selectAppInfo)
    const userInfo = useSelector(selectUserData)
    const navigate = useNavigate()

    const openContact = () => {
        navigate('/account', {replace:true, state:{activeModule:'contact'}})
    }

    return(
        <BottomSheet isOpen={show} onClose={closeBottomSheet} showExit={false}>
            <div className='mb-5 mb-md-4 mt-3 px-4 text-center'>
                {
                    thresholds?.find(t=>t.threshold_type === "certificate_generation")?.has_been_passed ?
                    <>
                        <img src={degree_img} style={{width:'45px'}} alt=''/>
                        <TitleH2 className='mb-2 mt-3'>Hai superato la prova</TitleH2>
                        <Text>Ti è stato inviato via mail il certificato di superamento, complimenti hai completato il percorso!</Text>
                        <Button className='w-100 mx-auto' onClick={closeBottomSheet}>
                            {t.buttons.close}
                        </Button>
                    </> : <>
                        {
                            
                            appInfo?.personalizations?.ofa_settings?.group_settings?.find(g => examData?.allowed_groups?.includes(g.video_completion_group_id)) ? 
                            <>
                                <img src={warning_img} style={{width:'45px'}} alt=''/>
                                <TitleH2 className='mb-2 mt-3'>Hai solo 1 altro tentativo, usalo bene!</TitleH2>
                                <Text>La prima Verifica è andata male. Prima di usare il secondo e ultimo tentativo, ripassa molto bene tutti gli argomenti della prova con i <strong>quiz delle varie unità</strong>.</Text>
                                <Button className='w-100 mx-auto' onClick={closeBottomSheet}>
                                    {t.buttons.keep_training}
                                </Button>
                            </> :
                            <>
                                {
                                    appInfo?.personalizations?.ofa_settings?.group_settings?.find(g => examData?.allowed_groups?.includes(g.retry_exam_group_id)) &&
                                    <>
                                        <img src={warning_img} style={{width:'45px'}} alt=''/>
                                        <TitleH2 className='mb-2 mt-3'>Hai terminato i tentativi disponibili</TitleH2>
                                        <Text>Putroppo non hai superato la prova di Verifica ufficiale OFA, <strong>contattaci</strong> per attivare sbloccare un ultimo tentativo.</Text>
                                        <Button className='w-100 mx-auto' onClick={openContact}>
                                            {t.account.contact}
                                        </Button>
                                    </>
                                }
                                
                            </>
                        }
                        
                    </>
                }
            </div>
        </BottomSheet>
    )
}